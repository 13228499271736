<template>
    <div class="icon-frame" :class="color">
        <v-mdi :name="'mdi' + computedIcon" class="mdi-inner-icon" />
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: 'ProgressWrench',
        },
        activeIcon: {
            type: String,
            default: '',
        },
        label: String,
        enabled: Boolean,
        defaultClass: {
            type: String,
            default: 'coreblue',
        },
        activeClass: {
            type: String,
            default: 'white',
        },
        textColor: {
            type: String,
            default: 'white',
        },
        useHeaderText: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        color() {
            return this.enabled ? this.activeClass : this.defaultClass;
        },
        computedIcon() {
            if (this.activeIcon.length > 0) {
                return this.enabled ? this.activeIcon : this.icon;
            } else {
                return this.icon;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.icon-frame {
    border: 0px solid;
    border-radius: 50%;
    background: $color-icon-background;
    color: $color-text-light;

    display: flex;
    align-items: center;
    justify-content: center;

    .mdi-inner-icon {
        height: 60%;
        width: 60%;
    }

    &:hover {
        background-color: $color-secondary;
    }

    &:active {
        background-color: $color-icon-background;
    }
}
</style>
