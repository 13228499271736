const nav = {
    namespaced: true,
    state() {
        return {
            orientation: 'landscape',
        };
    },
    mutations: {
        setOrientation(state, orientation) {
            state.orientation = orientation;
        },
    },
    getters: {},
};

export default nav;
