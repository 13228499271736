<template>
    <div id="content">
        <div id="title"><h1>Jeff McAleer</h1></div>
        <div id="bio">
            <p>Full stack developer focused on the commercial audiovisual market</p>
            <!-- <p>
                    I'm a software developer with a focus on the commercial
                    audiovisual market. I have a passion for creating a high
                    quality user experiences for the end user.
                </p>
                <p>
                    I develop front end user interfaces in VueJS and back end
                    programming in C#.
                </p>
                <p>
                    I am a Crestron Certified Gold Master Programmer, I hold the
                    Crestron SIMPL# Certification, and in the event it ever
                    comes in handy I still maintain a certification as an AMX
                    Programmer as well.
                </p> -->
        </div>
        <div id="links">
            <a href="https://github.com/jeffderek/" target="_blank">
                <MdiIconLink icon="Github" class="icon" />
            </a>
            <a href="https://www.linkedin.com/in/jeffderekmcaleer/" target="_blank">
                <MdiIconLink icon="Linkedin" class="icon"
            /></a>
            <a href="mailto:jeffmcaleer@gmail.com"> <MdiIconLink icon="At" class="icon" /></a>
            <a :href="`${publicPath}pdf/Jeff McAleer Resume 2022.pdf`" target="_blank"
                ><MdiIconLink icon="FileAccount" class="icon"
            /></a>
        </div>
        <img
            v-if="$store.state.nav.orientation == 'portrait'"
            alt="logo"
            id="logo"
            src="../assets/jeff-mcaleer-logo-dark-nolabel.png"
        />
    </div>
</template>

<script>
import MdiIconLink from '@/components/MdiIconLink.vue';

export default {
    name: 'Home',
    data() {
        return {
            publicPath: process.env.BASE_URL,
        };
    },
    components: {
        MdiIconLink,
    },
};
</script>

<style lang="scss">
#content {
    width: 100%;
    max-width: 850px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
    }
    #title {
        h1 {
            font-weight: bold;
            color: $color-text-dark;
        }
    }

    #bio {
        width: 100%;
        font-size: 1.5rem;
        text-align: center;
    }

    #links {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 80%;
        margin: 35px 0px;

        @media (min-width: 0px) {
            gap: 1rem;

            .icon {
                height: 75px;
                width: 75px;
            }
        }
        @media (min-width: (435px + $navsidebar-width)) {
            gap: 1.5rem;
            .icon {
                height: 90px;
                width: 90px;
            }
        }
        @media (min-width: (600px + $navsidebar-width)) {
            gap: 2rem;
            .icon {
                height: 120px;
                width: 120px;
            }
        }
    }

    #logo {
        position: fixed;
        bottom: 12px;
        right: 12px;
        width: 30%;
    }
}
</style>
