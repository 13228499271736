import { createStore } from 'vuex';
import nav from './modules/nav';

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        nav: nav,
    },
});
