import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { GraphQLClient } from 'graphql-request';
import VueMdijs from 'vue-mdijs';
import { mdiAt, mdiGithub, mdiLinkedin, mdiFileAccount, mdiLink, mdiProgressWrench } from '@mdi/js';

VueMdijs.add({
    mdiAt,
    mdiGithub,
    mdiLinkedin,
    mdiFileAccount,
    mdiLink,
    mdiProgressWrench,
});

const graphcmsClient = new GraphQLClient('https://api-us-east-1.graphcms.com/v2/cl28aqmt82xpz01z15t98c1ih/master');

const app = createApp(App);
app.mixin({
    beforeCreate() {
        this.$graphcms = graphcmsClient;
    },
});
app.use(VueMdijs);
app.use(store);
app.use(router);
app.mount('#app');
