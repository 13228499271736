<template>
    <header>
        <img alt="avatar" class="avatar" src="../assets/jeff-mcaleer-avatar.png" />
        <router-link v-for="route in $router.getRoutes()" :key="route.name" :to="{ name: route.name }" class="btn-nav">
            {{ route.name }}
        </router-link>
        <!-- <img alt="logo" id="logo" src="../assets/jeff-mcaleer-logo-nolabel.png" /> -->
    </header>
</template>

<style lang="scss">
header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: $navheader-height;
    z-index: 1;
    overflow-y: hidden;
    padding: 10px;

    background: $color-primary;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .avatar {
        width: 50px;
        height: 50px;
        background: $color-background;
        border-radius: 50%;
        border: 2px solid $color-secondary;
        margin-right: 10px;
    }

    .btn-nav {
        width: 100%;
        padding: 8px;
        border-radius: 5px;

        font-weight: bold;
        font-size: 1.2rem;
        text-align: center;
        color: $color-white;

        &:hover {
            background-color: darken($color-primary, 5);
            color: $color-white;
        }

        &.router-link-exact-active {
            background-color: $color-background;
            color: $color-primary;
        }
        &:active {
            background-color: $color-primary;
            color: $color-white;
        }
    }
}
</style>
