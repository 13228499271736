<template>
    <div id="projects">
        <div v-for="project in projects" :key="project.name" class="project">
            <div class="project-header">
                <div class="project-links">
                    <a v-if="project.link" :href="project.link" target="_blank">
                        <v-mdi name="mdiLink" class="mdi-inner-icon"
                    /></a>
                </div>
                <p class="project-name">{{ project.name }}</p>
                <div class="project-links">
                    <a v-if="project.github" :href="project.github" target="_blank">
                        <v-mdi name="mdiGithub" class="mdi-inner-icon"
                    /></a>
                </div>
            </div>
            <div class="project-description">{{ project.description }}</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            projects: [
                {
                    name: 'Zoom Room Native Room Controls Preview',
                    github: 'https://github.com/jeffderek/zoom-native-room-controls-preview',
                    link: 'https://zoom.jeffmcaleer.com',
                    description: 'WebApp for previewing Zoom Native Room Controls json files',
                },
                {
                    name: 'Crestron Command Finder',
                    github: 'https://github.com/jeffderek/crestron-command-finder',
                    link: 'https://ccf.jeffmcaleer.com',
                    description: 'A tool to make finding the right Crestron console command just a little bit easier',
                },
                {
                    name: 'Crestron Vue SIMPL Example',
                    github: 'https://github.com/jeffderek/crestron-vue-simpl-example',
                    description: 'Demo project showing how to use Vue with a Crestron SIMPL Windows Program',
                },
                {
                    name: 'Crestron Vue Websocket Example',
                    github: 'https://github.com/jeffderek/crestron-vue-websocket-example',
                    description:
                        'Demo project showing how to use Vue with a websocket in a 4 series Crestron SIMPL# Pro Program',
                },
            ],
        };
    },
};
</script>

<style lang="scss">
#projects {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .project {
        display: flex;
        flex-direction: column;
        align-items: center;

        .project-header {
            width: 100%;
            border-radius: 5px;

            font-weight: bold;
            font-size: 1.2rem;
            text-align: center;
            background-color: $color-primary;
            color: $color-white;

            display: grid;
            grid-template-columns: 1fr 6fr 1fr;

            .project-name {
                padding: 12px;
            }

            .project-links {
                a {
                    color: $color-white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 6px;

                    .mdi-icon {
                        height: 50px;
                        width: 50px;
                    }

                    &:hover {
                        color: $color-secondary;
                    }
                }
            }
        }
        .project-description {
            width: 98%;
            padding: 12px;
            border-radius: 0px 0px 5px 5px;
            border-color: $color-secondary;
            border-width: 0 3px 3px 3px;
            border-style: solid;
        }
    }
}
</style>
