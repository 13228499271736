<template>
    <div id="sidebar">
        <div id="nav">
            <img alt="avatar" class="avatar" src="../assets/jeff-mcaleer-avatar.png" />
            <router-link
                v-for="route in $router.getRoutes()"
                :key="route.name"
                :to="{ name: route.name }"
                class="btn-nav"
            >
                {{ route.name }}
            </router-link>
        </div>
        <img alt="logo" id="logo" src="../assets/jeff-mcaleer-logo-nolabel.png" />
    </div>
</template>

<style lang="scss">
#sidebar {
    height: 100%;
    width: $navsidebar-width;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    overflow-x: hidden;

    background: $color-primary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    #nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 14rem;
        gap: 6px;

        .avatar {
            margin: 20px;
            width: 200px;
            height: 200px;
            background: $color-background;
            border-radius: 50%;
            border: 8px solid $color-secondary;
        }

        .btn-nav {
            width: 100%;
            padding: 12px;
            border-radius: 5px;

            font-weight: bold;
            font-size: 1.2rem;
            text-align: center;
            color: $color-white;

            &:hover {
                background-color: darken($color-primary, 5);
                color: $color-white;
            }

            &.router-link-exact-active {
                background-color: $color-background;
                color: $color-primary;
            }
            &:active {
                background-color: $color-primary;
                color: $color-white;
            }
        }
    }

    #logo {
        padding: 10px 10px;
        width: 200px;
        position: absolute;
        bottom: 0;
    }
}
</style>
