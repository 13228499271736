<template>
    <NavSidebar v-if="$store.state.nav.orientation == 'landscape'" />
    <NavHeader v-else />

    <div id="router-container">
        <router-view />
    </div>
</template>

<script>
import NavSidebar from '@/components/NavSidebar';
import NavHeader from '@/components/NavHeader';

export default {
    components: {
        NavSidebar,
        NavHeader,
    },
    mounted() {
        var mql = window.matchMedia('(orientation: portrait)');
        if (mql.matches) {
            this.$store.commit('nav/setOrientation', 'portrait');
        } else {
            this.$store.commit('nav/setOrientation', 'landscape');
        }

        mql.addEventListener('change', (m) => {
            if (m.matches) {
                this.$store.commit('nav/setOrientation', 'portrait');
            } else {
                this.$store.commit('nav/setOrientation', 'landscape');
            }
        });
    },
};
</script>

<style lang="scss">
@import './styles/style.scss';

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;

    height: 100%;
    width: 100%;

    //display: flex;

    #router-container {
        @media (orientation: landscape) {
            margin-left: $navsidebar-width;
            justify-content: center;

            @media (min-height: 600px) {
                padding-bottom: 10%;
            }
        }

        @media (orientation: portrait) {
            margin-top: $navheader-height;
            justify-content: flex-start;
        }

        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 40px;
        height: 100%;
        overflow: auto;
    }
}
</style>
